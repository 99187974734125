import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import defineGuards from './defineGuards/defineGuards';
import IsAuthLayer from './layers/isAuthLayer';
import isAuthLayer from './layers/isAuthLayer';
import IsNotAuthLayer from './layers/IsNotAuthLayer';
import IsForgetPassword from './layers/isForgetPassword';

const Courses = lazy(() => import('view/pages/courses'));
const Login = lazy(() => import('view/pages/auth/login'));
const Schedule = lazy(() => import('view/pages/schedule'));
const Languages = lazy(() => import('view/pages/languages'));
const Categories = lazy(() => import('view/pages/categories'));
const Menu = lazy(() => import('view/pages/landingPage/menu'));
const Translations = lazy(() => import('view/pages/translations'));
const ProfileSetUp = lazy(() => import('view/pages/editUserProfile'));
const EditUserProfile = lazy(() => import('view/pages/editUserProfile'));
const UserManagement = lazy(() => import('view/pages/userManagement'));
const AddUser = lazy(() => import('view/pages/userManagement/addUser'));
const UserProfileSetUp = lazy(() => import('view/pages/userProfileSetUp'));
const EditUser = lazy(() => import('view/pages/userManagement/editUser'));
const ResetPassword = lazy(() => import('view/pages/auth/resetPassword'));
const ForgetPassword = lazy(() => import('view/pages/auth/forgetPassword'));
const Instructors = lazy(() => import('view/pages/landingPage/instructors'));
const Subscribers = lazy(() => import('view/pages/landingPage/subscribers'));
const UserMessages = lazy(() => import('view/pages/landingPage/userMessages'));
const AddCourseSetup = lazy(() => import('view/pages/courses/addCourseSetup'));
const EditCourseSetup = lazy(() => import('view/pages/courses/editCourseSetup'));
const PromotionBanner = lazy(() => import('view/pages/landingPage/promotionBanner'));
const SuccessForgetPassword = lazy(() => import('view/pages/successForgetPassword'));
const CoursesCarousel = lazy(() => import('view/pages/landingPage/coursesCarousel'));
const ContentManagement = lazy(() => import('view/pages/landingPage/contentManagement'));
const BroadcastNews = lazy(() => import('view/pages/landingPage/subscribers/emails/broadcastNews'));
const EditSection = lazy(() => import('view/pages/landingPage/contentManagement/sections/editSection'));
const Dashboard = lazy(() => import('view/pages/dashboard'));
const Feedbacks = lazy(() => import('view/pages/feedbacks'));
const Coupons = lazy(() => import('view/pages/coupons'));
const Certificates = lazy(() => import('view/pages/certificates'));
const EmailTemplates = lazy(() => import('view/pages/emailTemplates'));
const AddEmailTemplate = lazy(() => import('view/pages/emailTemplates/addEmailTemplate'));
const EditEmailTemplate = lazy(() => import('view/pages/emailTemplates/editEmailTemplate'));
const Revenues = lazy(() => import('view/pages/financials/revenues'));
const Payments = lazy(() => import('view/pages/financials/payments'));
const Chat = lazy(() => import('view/pages/chat'));
/* A constant that is used to define the routes of the application. */

export const ROUTE_CONSTANTS = {
	EMPTY: '/',
	CHAT: '/chat',
	LOGIN: '/login',
	COUPONS: '/coupons',
	COURSES: '/courses',
	ADD_USER: '/add-user',
	SCHEDULE: '/schedule',
	REVENUES: '/revenues',
	FEEDBACKS: '/feedbacks',
	PAYMENTS: '/payments',
	EDIT_USER: '/edit-user',
	LANGUAGES: '/languages',
	DASHBOARD: '/dashboard',
	MENU_ITEMS: '/menu-items',
	CATEGORIES: '/categories',
	ADD_COURSES: '/add-courses',
	INSTRUCTORS: '/instructors',
	EDIT_SECTION: '/edit_section',
	CERTIFICATES: '/certificates',
	EDIT_COURSES: '/edit-courses',
	TRANSLATIONS: '/translations',
	LANDING_PAGE: '/landing-page',
	PROFILE_SETUP: '/profile-setup',
	COURSE_DETAIL: '/course-detail',
	USER_MESSAGES: '/user-messages',
	RESET_PASSWORD: '/reset-password',
	BROADCAST_NEWS: '/broadcast-news',
	EMAIL_TEMPLATES: '/email-templates',
	FORGET_PASSWORD: '/forget-password',
	NEW_SUBSCRIBERS: '/news-subscribers',
	USER_MANAGEMENT: '/user-management',
	PROMOTION_BANNER: '/promotion-banner',
	COURSES_CAROUSEL: '/courses-carousel',
	EDIT_USER_PROFILE: '/edit-user-profile',
	ADD_EMAIL_TEMPLATE: '/add-email-template',
	CONTENT_MANAGEMENT: '/content-management',
	EDIT_EMAIL_TEMPLATE: '/edit-email-template',
	EDIT_STUDENT_PROFILE: '/edit-student-profile',
	EDIT_INSTRUCTOR_PROFILE: '/edit-instructor-profile',
	SUCCESS_FORGET_PASSWORD: '/success-forget-password',
	COURSE_DETAIL_SEO: '/fb/course-detail',
	COURSE_DETAIL_SEO_FACEBOOK: '/fb/course-detail'
};

export const rootRoutes = [
	{
		path: ROUTE_CONSTANTS.EMPTY,
		component: () => <Redirect to={ROUTE_CONSTANTS.LOGIN}/>,
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.LOGIN,
		component: () => defineGuards([ IsNotAuthLayer ], Login),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.FORGET_PASSWORD,
		component: () => defineGuards([ IsNotAuthLayer ], ForgetPassword),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.SUCCESS_FORGET_PASSWORD,
		component: () => defineGuards([ IsForgetPassword ], SuccessForgetPassword),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.RESET_PASSWORD,
		component: ResetPassword,
		exact: true
	}
];


export const instructorRouters = [
	{
		path: ROUTE_CONSTANTS.LOGIN,
		component: () => defineGuards([ IsNotAuthLayer ], Login),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.EMPTY,
		component: () => <Redirect to={ROUTE_CONSTANTS.LOGIN}/>,
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.PROFILE_SETUP,
		component: () => defineGuards([ IsAuthLayer ], ProfileSetUp),
		exact: true
	}
];

export const adminRouters = [
	...rootRoutes,
	{
		path: ROUTE_CONSTANTS.FEEDBACKS,
		component: () => defineGuards([ isAuthLayer ], Feedbacks),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.COUPONS,
		component: () => defineGuards([ isAuthLayer ], Coupons),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.DASHBOARD,
		component: () => defineGuards([ isAuthLayer ], Dashboard),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.COURSES,
		component: () => defineGuards([ isAuthLayer ], Courses),
		exact: true
	},

	{
		path: ROUTE_CONSTANTS.CHAT,
		component: () => defineGuards([ isAuthLayer ], Chat),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.COURSES}${ROUTE_CONSTANTS.ADD_COURSES}`,
		component: () => defineGuards([ isAuthLayer ], AddCourseSetup),
		exact: true
	},

	{
		path: `${ROUTE_CONSTANTS.COURSES}${ROUTE_CONSTANTS.EDIT_COURSES}/:courseId`,
		component: () => defineGuards([ isAuthLayer ], EditCourseSetup),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.EMAIL_TEMPLATES,
		component: () => defineGuards([ isAuthLayer ], EmailTemplates),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.EMAIL_TEMPLATES}${ROUTE_CONSTANTS.ADD_EMAIL_TEMPLATE}`,
		component: () => defineGuards([ isAuthLayer ], AddEmailTemplate),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.EMAIL_TEMPLATES}${ROUTE_CONSTANTS.EDIT_EMAIL_TEMPLATE}/:templateId`,
		component: () => defineGuards([ isAuthLayer ], EditEmailTemplate),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.LANGUAGES,
		component: () => defineGuards([ IsAuthLayer ], Languages),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.USER_MANAGEMENT,
		component: () => defineGuards([ IsAuthLayer ], UserManagement),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.USER_MANAGEMENT}${ROUTE_CONSTANTS.ADD_USER}`,
		component: () => defineGuards([ IsAuthLayer ], AddUser),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.CERTIFICATES,
		component: () => defineGuards([ IsAuthLayer ], Certificates),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.USER_MANAGEMENT}${ROUTE_CONSTANTS.EDIT_USER}/:userId`,
		component: () => defineGuards([ IsAuthLayer ], EditUser),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.TRANSLATIONS,
		component: () => defineGuards([ IsAuthLayer ], Translations),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.PROFILE_SETUP,
		component: () => defineGuards([ IsAuthLayer ], UserProfileSetUp),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.PROFILE_SETUP}${ROUTE_CONSTANTS.EDIT_USER_PROFILE}/:userId`,
		component: () => defineGuards([ IsAuthLayer ], EditUserProfile),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.CATEGORIES,
		component: () => defineGuards([ IsAuthLayer ], Categories),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.SCHEDULE,
		component: () => defineGuards([ IsAuthLayer ], Schedule),
		exact: true
	},

	{
		path: ROUTE_CONSTANTS.MENU_ITEMS,
		component: () => defineGuards([ IsAuthLayer ], Menu),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.NEW_SUBSCRIBERS,
		component: () => defineGuards([ IsAuthLayer ], Subscribers),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.USER_MESSAGES,
		component: () => defineGuards([ IsAuthLayer ], UserMessages),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.CONTENT_MANAGEMENT,
		component: () => defineGuards([ IsAuthLayer ], ContentManagement),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.NEW_SUBSCRIBERS}${ROUTE_CONSTANTS.BROADCAST_NEWS}`,
		component: () => defineGuards([ IsAuthLayer ], BroadcastNews),
		exact: true
	},
	{
		path: `${ROUTE_CONSTANTS.CONTENT_MANAGEMENT}${ROUTE_CONSTANTS.EDIT_SECTION}/:sectionId`,
		component: () => defineGuards([ IsAuthLayer ], EditSection),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.COURSES_CAROUSEL,
		component: () => defineGuards([ IsAuthLayer ], CoursesCarousel),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.PROMOTION_BANNER,
		component: () => defineGuards([ IsAuthLayer ], PromotionBanner),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.INSTRUCTORS,
		component: () => defineGuards([ IsAuthLayer ], Instructors),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.REVENUES,
		component: () => defineGuards([ IsAuthLayer ], Revenues),
		exact: true
	},
	{
		path: ROUTE_CONSTANTS.PAYMENTS,
		component: () => defineGuards([ IsAuthLayer ], Payments),
		exact: true
	}
];

